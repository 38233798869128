<template>
  <div>
   
    <b-button-group v-if="servicesDetails.linkId" class="mb-1">
      <b-button
        :variant="!+lang ? 'primary' : 'outline-primary'"
        :to="'/main/services/details/' + (servicesDetails.language == 1 ? id : servicesDetails.linkId) + '/0'"
        >عربي</b-button
      >
      <b-button
        :to="'/main/services/details/' + (servicesDetails.language == 2 ? id : servicesDetails.linkId) + '/1'"
        :variant="!+lang ? 'outline-primary' : 'primary'"
        >English</b-button
      >
    </b-button-group>
     <ValidationObserver ref="observer">
    <b-form
      @submit.prevent="onSubmit"
   
    >
    <b-card no-body class="mb-2">
      <b-card-header class="align-items-center">
        <h4 class="mr-auto mb-0">تفاصيل المنتج</h4>
      </b-card-header>
      <b-card-body>
        <b-card-text>
          <b-row>
            <b-col cols="12" md="6">
              <BTextInputWithValidation
                rules="required"
                name="اسم المستخدم"
                label="سم الخدمة"
                placeholder="ادخل اسم المستخدم"
                  v-model="servicesDetails.name" />
                           </b-col>
            <b-col cols="12" md="6">
              <BTextInputWithValidation
                rules="required"
                name="رقم المستخدم"
                label="رقم الخدمة"
                placeholder="ادخل رقم الخدمة"
                 v-model="servicesDetails.order"
              />
            </b-col>
            <b-col cols="12" md="6">
                <div class="editor-container mt-2">
                  <quill-editor
                    class="editor"
                    ref="myTextEditor"
                    :value="servicesDetails.description"
                    :options="editorOption"
                    @change="onEditorChange"
                  />
                </div>
                <small class="text-danger mt-5" v-show="!servicesDetails.description">شرح المرحلة مطلوب</small>
            </b-col>
            <b-col cols="12" md="6">
              <label class="mt-1">صورة </label>
              <fileDragDrop
                @inValidFile="unValidThumbImage"
                @uploaded="uploadThumbImage"
                id="employee-photo"
                height="100px"
                type="image"
                title="صورة جديدة"
              />
              <img
                :src="!servicesDetails.imageDto.base64 ? servicesDetails.imageDto.path.replace('~', $store.getters['app/domainHost']) : servicesDetails.imageDto.base64"
                style="max-height: 320px; object-fit: fill;"
                class="w-100"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col>
            <div class="d-flex">
              <b-button class="mr-1"  type="submit"  variant="primary" style="max-width:100px"
              @click="updateServices(servicesDetails)" 
                >تعديل</b-button
              >
              <b-button  variant="outline-primary" style="max-width:100px" 
              to="../"
                >تراجع</b-button
              >
            </div>
          </b-col>
          <b-col style="
            display: flex;
            justify-content: flex-end;">
            <b-button @click="deleteItemServices(id)" style="max-width:100px" variant="outline-primary"
              >حذف</b-button
            >
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
    </b-form>
  </ValidationObserver>
      
    
  </div>
</template>
<script>
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { ValidationObserver } from "vee-validate";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import {mapActions, mapGetters} from "vuex";
import { quillEditor } from "vue-quill-editor";
import hljs from 'highlight.js'
export default {
  props: {
    lang: String,
    id: String,
  },
   created() {
    this.getServicesById({type: 0, id: this.id})
  },
  components: {
    fileDragDrop,
    BTextInputWithValidation,
    ValidationObserver,
    quillEditor
  },
   computed: {
    ...mapGetters(["servicesDetails"])
  },
  data: () => ({
    text: "",
      editorOption: {
         modules: {
              toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                  ['link', 'image', 'video']
              ],
          syntax: {
              highlight: text => hljs.highlightAuto(text).value
          }
        },
        placeholder: "ادخل شرح عن المرحلة",
      },
  }),
  methods: {

     ...mapActions(["getServicesById","updateServices","deleteItemServices"]),
     onSubmit() {
        this.$refs.observer.validate().then((success) => {
          if(success && this.servicesDetails.imageDto.base64) {
             this.updateActivities({
              id: 0,
              name:this.servicesDetails.name,
              description: this.servicesDetails.description,
              order: this.servicesDetails.order,
              language: this.servicesDetails.language,
              imageDto: {
              id: 0,
              path: null,
              base64: this.servicesDetails.imageDto.base64
            }
            });
          }
        })
    },
    uploadThumbImage(file) {
        this.servicesDetails.imageDto.base64 = file[0].base64;
    },
    unValidThumbImage() {
      console.log("un valid Thumb file");
    },
      onEditorChange(value) {
        this.servicesDetails.description = value.html
      },
  },
};
</script>
